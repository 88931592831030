.orderDetailSection .isoInputBox .isoInputFieldset label {
  font-weight: 500 !important;
  line-height: initial !important;
  margin-right: 10px !important;
  margin-bottom: 0;
  width: 120px;
  align-self: center;
}

.isoInputBox .ant-form-item-control {
  line-height: initial;
}

.edit-form .edit-form-item-upload .ant-form-item-control {
  line-height: normal;
}

.isoInputBox .ant-form-item {
  margin-bottom: 0;
}

.has-error .ant-input-number,
.has-error .ant-time-picker-input {
  border-color: #f5222d !important;
}

.ant-input-number-input:disabled {
  color: rgba(0, 0, 0, 0.8) !important;
}

.ant-select {
  color: black !important;
}
.ant-input-number-disabled,
.ant-input[disabled],
.ant-select-disabled .ant-select-selection {
  background-color: #f5f5f594 !important;
}

.isoInputBox .ant-input-group .ant-select-selection--single {
  height: 35px !important;
  padding: 1px !important;
  min-width: 35px !important;
}

.user_balance {
  font-size: 20px;
  font-weight: bold;
  color: aliceblue;
  text-align: center;
  padding: 10px;
}

.frame .ant-tooltip-inner {
  background-color: transparent;
}

.orderEditTable .ant-select-disabled,
.orderEditTable .ant-input[disabled] {
  color: rgba(0, 0, 0, 0.8);
}

tr.free-product td {
  color: #65b201 !important;
}

input {
  color: black !important;
}

.cyan {
  background-color: #08979c !important;
  border-color: #08979c !important;
}

.orange {
  background-color: #d46b08 !important;
  border-color: #d46b08 !important;
}

.yellow {
  background-color: #d4b106 !important;
  border-color: #d4b106 !important;
}

.purple {
  background-color: #531dab !important;
  border-color: #531dab !important;
}

.lime {
  background-color: #254000 !important;
  border-color: #254000 !important;
}

.color-complete {
  background-color: #3c9502 !important;
  border-color: #3c9502 !important;
}

.color-process {
  background-color: #010762 !important;
  border-color: #010762 !important;
}

.ql-snow .ql-picker {
  height: auto !important;
}

.ql-editor {
  min-height: 100px !important;
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}

.isoLayoutContentWrapper {
  height: 100%;
}

.editView {
  height: 90%;
}
